@import "mixins"
.account
  position: relative
  min-height: 100%

.userInfo
  @include flex
  justify-content: space-between
  height: 40px
  padding: 0 12px 3px
  position: relative
  color: #fff
  @include bold
  &:before
    content: ""
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    height: 1px
    background: var(--account_borderLight)
    border-top: 1px solid var(--account_borderDark)
    border-bottom: 1px solid var(--account_borderDark)
    box-shadow: 0 1px var(--account_borderLight)

.userName
  @include ellipsis
  :global(.svg-icon)
    min-width: 11px
    margin-right: 12px
    display: inline-block
    vertical-align: top
    span
      @include flex
    svg
      path
        fill: var(--account_signOutIconFill)

.id
  @include flex
  min-width: 100px
  &:before
    content: ""
    @include separator(var(--account_IdSeparator))
    margin: 0 8px
    background-position: left
  span
    color: var(--account_signOutIconFill)
    font-weight: normal
    margin-right: 8px

.profileNav
  padding: 10px 10px 140px
  list-style: none
  li
    margin-bottom: 10px
    border-radius: 3px
    overflow: hidden
    background: var(--acount_menuItemContentBg)
    > div:first-of-type,
    > a
      background: var(--acount_menuItemBg)
      @include flex
      height: 50px
      padding: 0 16px
      border-radius: 0 3px 3px 0
      box-shadow: inset -1px 0 0 rgb(var(--particularColor))
      position: relative
      z-index: 1
      :global(.svg-icon)
        min-width: 20px
        path
          fill: var(--acount_menuItemBg)
        :global(.account-menu-item-bg)
          fill: rgb(var(--primaryColor))
        svg
          width: 20px
          height: 18px
      .arrow
        width: 10px
        height: 5px
        min-width: 10px
        background: var(--icon-arrow) no-repeat
        transform-origin: 45% 50%
        transform: rotate(-90deg)
        transition: all 0.2s
        margin-left: auto
      .title
        color: rgba(var(--primaryColor), 0.8)
        position: relative
        padding-left: 26px
        &:before
          content: ""
          @include separator(var(--sport_separator))
          position: absolute
          left: 6px
          height: 28px
        > span
          display: block
          color: #fff
          margin-bottom: 4px
          text-transform: uppercase

      &:global(.activeLink)
        :global(.svg-icon)
          path
            fill: var(--account_iconFill)!important
          :global(.account-menu-item-bg)
            fill: var(--account_iconBg)!important

    &.opened,
    .active
      > div:first-of-type :global(.svg-icon)
        path
          fill: var(--account_iconFill)
        :global(.account-menu-item-bg)
          fill: var(--account_iconBg)
    &.opened
      > div:first-of-type,
      > a
        border-radius: 3px 3px 0 0
        box-shadow: inset 0 1px 0 rgb(var(--particularColor))
        background: var(--acount_menuItemOpenedBg)
        .arrow
          transform: none
  a
    color: rgb(var(--primaryColor))

.container
  > a:not(:last-of-type)
    border-bottom: 1px dotted var(--acount_menuItemBorder)
  > a,
  .composeMsgBtn
    height: 40px
    margin: 0 10px
    @include flex
    color: #fff
    position: relative
    .verificationReminder
      left: 20px
      top: 19px

.composeMsgBtn
  background: transparent
  padding: 0
  text-transform: unset
  width: -webkit-fill-available
  justify-content: unset
  font-weight: unset
  border-top: 1px dotted var(--acount_menuItemBorder)
  border-radius: 0

.openedMenuIcon
  min-width: 20px
  margin: 0 16px 0 6px
  @include flex
  > span
    @include flex
  svg
    width: 20px
    height: 18px
    path
      fill: var(--acount_menuItemContentBg)
    :global(.account-menu-item-bg)
      fill: rgb(var(--primaryColor))

.active .openedMenuIcon,
.active .twoStepIcon
  path
    fill: var(--account_iconFill)
  :global(.account-menu-item-bg)
    fill: var(--account_iconBg)

.messagesOpened
  :global(.svg-icon) svg
    width: 18px
    height: 18px
  :global(.svg-icon)
    margin: 0 16px 0 6px
    svg
      path
        fill: rgb(var(--primaryColor))
  .active :global(.svg-icon) svg path
    fill: var(--account_iconBg)

.chatServiceBtn
  width: 100%
  height: 50px
  padding: 0 13px
  justify-content: flex-start
  position: relative
  &:before
    @include separator(var(--button_color))
    position: absolute
    left: 40px
    height: 28px
  .title
    padding-left: 22px
    font-size: 14px
  svg
    width: 24px
    height: auto
    path
      fill: var(--button_color)

.signOutBtn
  background: var(--account_signOutBg)
  color: #fff
  font-weight: normal
  text-transform: none
  width: 120px
  position: absolute
  left: 50%
  transform: translateX(-50%)
  bottom: 20px
  :global(.svg-icon)
    margin-right: 14px
    svg
      path
        fill: var(--account_signOutIconFill)

.verificationReminder
  background: rgb(var(--particularColor2))
  @include flex
  justify-content: center
  width: 16px
  height: 16px
  position: absolute
  left: 30px
  top: 24px
  border-radius: 100%
  > span
    @include flex
    justify-content: center
  svg
    width: 6px
    height: 10px
    path
      fill: #fff

.notVerifyTooltip
  position: unset
  transform: translate(0, -90px)
  margin: auto
